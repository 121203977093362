export const CLINIC = 'Clinic Block';
export const SURGERY = 'Surgery Block';
export const ONCALL = 'On Call Coverage Duty';
export const SUPPLEMENTAL = 'Supplemental Service';
export const ADDON = 'Add-On Service';
export const ADMINISTRATIVE = 'Administrative / Miscellaneous Service';
export const PROCEDUREREADING = 'Procedure Reading / Implant Block';
export const ONCALLSERVICE = 'On-Site On-Call Service';
export const HOSPICE = 'Hospice Clinical Consult Services';
export const HIT = 'Additional Compensation Services';
export const ED = 'ED Service';
export const SCRIBE = 'Scribe Service';
export const HOSPITALIST = 'Hospitalist Service Coverage';