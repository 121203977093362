import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import SideBar from "./../../Components/Sidebar";
import { Checkbox, Icon, Intent } from "@blueprintjs/core";
import AddNewEntity from "./../../images/addEntity.png";
import SelectArrow from "./../../images/selectArrow.png";
import OpenFolderBlue from "./../../images/openFolderBlue.png";
import CloseFolderBlue from "./../../images/closeFolderBlue.png";
import IndustriesEntityFolder from "./../../images/industriesEntityFolder.png";
import DeleteHcRow from "./../../images/deleteHcRow.png";
import EditHcRow from "./../../images/editHcRow.png";
import CrossPink from "./../../images/crossPink.png";
import AddMileageRateForCustomer from "./addMileageRateForCustomer";
import { GET, DELETE, POST, TenantID, PUT } from "./../dataSaver";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DeleteConfirmation from "../../Components/DeleteConfirmation";
import { InputGroup } from "@blueprintjs/core";
import style from "./index.module.scss";
import LevelTwoHeader from "../../Components/LevelTwoHeader";
import CommonSwitch from '../../Components/CommonFields/CommonSwitch';
import CommonPurpleCheckBox from "../../Components/CommonFields/CommonPurpleCheckBox";

const GeneralConfigurationForCustomers = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [showAddCompanyDialog, setShowAddCompanyDialog] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMileageRate, setSelectedMileageRate] = useState({});
  const [entityId, setEntityId] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [mileageRateData, setMileageRateData] = useState([]);
  const [generalConfig, setGeneralConfig] = useState([]);
  const [vendorDataRequired, setVendorDataRequired] = useState(false);
  const getAddMileageRateDialog = (value) => {
    setShowAddCompanyDialog(value);
  };

  const getIsExpanded = (value) => {
    setIsExpanded(value);
  };

  useEffect(() => {
    getIndustryData();
    getGeneralConfig();
  }, []);

  useEffect(() => {
    getMileageRateList();
  }, []);

  useEffect(() => {
    if (entityId !== "" && entityId !== undefined) {
      getLastModifiedDate();
    }
  }, [entityId]);

  const getIndustryData = async () => {
    const { data: entity } = await GET(`entity-service/entity/${TenantID}`);
    setEntityId(entity?.id);
  };

  const getLastModifiedDate = async () => {
    const { data: lastModifiedDate } = await GET(
      `entity-service/referenceList/entity/${entityId}`
    );
    const date = new Date(lastModifiedDate.holidayList?.lastModified);
    setLastUpdatedDate(format(date, "MMM d, yyyy HH:mm"));
  };

  const getMileageRateList = async () => {
    const { data: mileageRateData } = await GET(
      `entity-service/mileageRate?X-tenantID=${TenantID}`
    );
    setMileageRateData(mileageRateData)
  };

  const handleDeleteMileageRate = async (id) => {
    await DELETE(`entity-service/mileageRate/${id}`)
      .then((response) => {
        SuccessToaster("Mileage Rate Deleted Successfully");
        getMileageRateList();
        // getLastModifiedDate();
      })
      .catch((error) => {
        ErrorToaster(error);
      });
  };


  const getShowDeleteConfirmation = (value) => {
    setShowDeleteConfirmation(value);
  };

  const getDeleteConfirmation = (value) => {
    if (value) {
      // handleDeleteMileageRate(mileageId);
    }
  };

  const getGeneralConfig = async () => {
    const { data: data } = await GET(
      `entity-service/generalConfig`
    );
    if (data) {
      setGeneralConfig(data);
      console.log('general config', data)
      setVendorDataRequired(data?.vendorExternalSystemInfoRequired)
    }
  }

  const handleSave = async () => {
    if (generalConfig) {
      let data = {
        "entityId": {
          "id": entityId
        },
        "vendorExternalSystemInfoRequired": vendorDataRequired
      }

      await POST('entity-service/generalConfig', JSON.stringify(data))
        .then((response) => {
          SuccessToaster("Config Added Successfully");
        })
        .catch((error) => {
          ErrorToaster(error);
        });
    } else {
      let data = generalConfig;
      data.vendorExternalSystemInfoRequired = vendorDataRequired;
      await PUT(`entity-service/generalConfig/${data?.id}`, JSON.stringify(data))
        .then((response) => {
          SuccessToaster("Config Updated Successfully");
        })
        .catch((error) => {
          ErrorToaster(error);
        });
    }
  }

  console.log('vendor data required', vendorDataRequired)

  return (
    <Fragment>
      <div>
        <Navbar />
        <div className={style.margin20}>
          <div
            className={`${isExpanded ? style.bigCardGrid : style.smallCardGrid
              }`}
          >
            <div>
              <SideBar isExpanded={isExpanded} getIsExpanded={getIsExpanded}>
                <div></div>
              </SideBar>
            </div>
            <div>
              <LevelTwoHeader
                heading={"GENERAL CONFIGURATION"}
                // updatedTime={`UPDATED ON ${lastUpdatedDate} `}
                path={"/Screens/ReferenceList/customerAdminDashboard"}
                callingFrom={"Customer Admin"}
                needHeader={true}
              />

              <div className={style.marginTop35}>
                <div className={style.centreCardStyle}>
                  <div className={style.margin20}>
                    <div className={style.customersAdminColumngrid3}>
                      <div></div>
                      <div>
                        <div className={`${style.customersAdminCardStyle3} `}>
                          <div className={`${style.editHealthCareGrid2} ${style.marginLeft20} ${style.marginTop10}`}>
                            <div className={style.entityLableStyle3}>Certification Language for Approver*</div>
                            <div className={style.displayInRow}>
                              <InputGroup
                                value={""}
                                className={style.halfWidth}
                              />
                            </div>
                          </div>
                          <div className={`${style.editHealthCareGrid2} ${style.marginLeft20} ${style.marginTop10}`}>
                            <div className={style.entityLableStyle3}>Certification Language for Reviewer*</div>
                            <div className={style.displayInRow}>
                              <InputGroup
                                value={""}
                                className={style.halfWidth}
                              />
                            </div>
                          </div>
                          <div className={`${style.editHealthCareGrid2} ${style.marginLeft20} ${style.marginTop10}`}>
                            <div className={style.entityLableStyle3}>Certification Language for Accounts Payable*</div>
                            <div className={style.displayInRow}>
                              <InputGroup
                                value={""}
                                className={style.halfWidth}
                              />
                            </div>
                          </div>
                          <div className={`${style.editHealthCareGrid2} ${style.marginLeft20} ${style.marginTop10}`}>
                            <div className={style.entityLableStyle3}>Vendor External System Info Required*</div>
                            <div className={style.displayInRow}>
                              <CommonSwitch
                                label={vendorDataRequired === true ? "YES" : "NO"}
                                className={`${style.textAlignLeft} ${style.switchFontStyle} ${style.flexLeft}`}
                                onChange={() =>
                                  setVendorDataRequired(!vendorDataRequired)
                                }
                                checked={vendorDataRequired}
                              />
                            </div>
                          </div>



                        </div>
                        <div className={`${style.floatRight} ${style.marginTop10}`}>
                          <button
                            className={`${style.buttonStyle} ${style.marginLeft20}`}
                            onClick={() => handleSave()}
                          >
                            SAVE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.spaceBetween}>
            <p className={style.poweredBy}>Powered by - TimeSmartAI.Inc LLP</p>
            <p className={style.poweredBy}>© TimeSmartAI.Inc</p>
          </div>
        </div>
      </div>

      {showAddCompanyDialog && (
        <AddMileageRateForCustomer
          getAddMileageRateDialog={getAddMileageRateDialog}
          isEdit={isEdit}
          getMileageRateData={getMileageRateList}
          mileageRateData={mileageRateData}
          selectedYear={selectedYear}
          selectedMileageRate={selectedMileageRate}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          getShowDeleteConfirmation={getShowDeleteConfirmation}
          getDeleteConfirmation={getDeleteConfirmation}
          confirmationText="Do you want to delete this holiday?"
        />
      )}
    </Fragment>
  );
};

export default GeneralConfigurationForCustomers;
