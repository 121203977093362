import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import SideBar from "./../../Components/Sidebar";
import { Checkbox, Icon, Intent } from "@blueprintjs/core";
import AddNewEntity from "./../../images/addEntity.png";
import SelectArrow from "./../../images/selectArrow.png";
import OpenFolderBlue from "./../../images/openFolderBlue.png";
import CloseFolderBlue from "./../../images/closeFolderBlue.png";
import IndustriesEntityFolder from "./../../images/industriesEntityFolder.png";
import DeleteHcRow from "./../../images/deleteHcRow.png";
import EditHcRow from "./../../images/editHcRow.png";
import CrossPink from "./../../images/crossPink.png";
import AddRegionsForCustomer from "./addRegionsForCustomer";
import { GET, DELETE, POST, TenantID } from "./../dataSaver";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DeleteConfirmation from "../../Components/DeleteConfirmation";
import { InputGroup } from "@blueprintjs/core";
import style from "./index.module.scss";
import LevelTwoHeader from "../../Components/LevelTwoHeader";
import CommonPurpleCheckBox from "../../Components/CommonFields/CommonPurpleCheckBox";

const RegionsForCustomers = () => {
  const [showAddRegionDialog, setShowAddRegionDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedRegion, setSelectedRegion] = useState({});
  const [entityId, setEntityId] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [regionsData, setRegionsData] = useState([]);

  const getAddRegionsDialog = (value) => {
    setShowAddRegionDialog(value);
  };

  const getIsExpanded = (value) => {
    setIsExpanded(value);
  };

  useEffect(() => {
    getIndustryData();
  }, []);

  useEffect(() => {
    getRegionsList();
  }, []);

  useEffect(() => {
    if (entityId !== "" && entityId !== undefined) {
      getLastModifiedDate();
    }
  }, [entityId]);

  const getIndustryData = async () => {
    const { data: entity } = await GET(`entity-service/entity/${TenantID}`);
    setEntityId(entity?.id);
  };

  const getLastModifiedDate = async () => {
    const { data: lastModifiedDate } = await GET(
      `entity-service/referenceList/entity/${entityId}`
    );
    const date = new Date(lastModifiedDate.holidayList?.lastModified);
    setLastUpdatedDate(format(date, "MMM d, yyyy HH:mm"));
  };

  const getRegionsList = async () => {
    const { data: regionsData } = await GET(
      `entity-service/region?X-tenantID=${TenantID}`
    );
    setRegionsData(regionsData)
  };

  const handleDeleteRegion = async (id) => {
    await DELETE(`entity-service/region/${id}`)
      .then((response) => {
        SuccessToaster("Region Deleted Successfully");
        getRegionsList();
        // getLastModifiedDate();
      })
      .catch((error) => {
        ErrorToaster(error);
      });
  };


  const getShowDeleteConfirmation = (value) => {
    setShowDeleteConfirmation(value);
  };

  const getDeleteConfirmation = (value) => {
    if (value) {
      // handleDeleteRegion(mileageId);
    }
  };

  return (
    <Fragment>
      <div>
        <Navbar />
        <div className={style.margin20}>
          <div
            className={`${isExpanded ? style.bigCardGrid : style.smallCardGrid
              }`}
          >
            <div>
              <SideBar isExpanded={isExpanded} getIsExpanded={getIsExpanded}>
                <div></div>
              </SideBar>
            </div>
            <div>
              <LevelTwoHeader
                heading={"Regions For Customer"}
                // updatedTime={`UPDATED ON ${lastUpdatedDate} `}
                path={"/Screens/ReferenceList/customerAdminDashboard"}
                callingFrom={"Customer Admin"}
                needHeader={true}
              />

              <div className={style.marginTop35}>
                <div className={style.centreCardStyle}>
                  <div className={style.margin20}>
                    <div className={style.customersAdminColumngrid3}>
                      <div></div>
                      <div>
                        <div className={style.customersAdminCardStyle3}>
                          <div className={`${style.RegionsHeaderInsideContainer} ${style.displayInRow}`}>
                            <p></p>
                            <p className={`${style.tableHeaderIndustriesFontStyle7} `}>
                              Regions</p>
                            <p className={`${style.tableHeaderIndustriesFontStyle7}`}>
                              Edit </p>
                            <p className={`${style.tableHeaderIndustriesFontStyle7} `}>
                              Delete </p>
                            <img
                              src={AddNewEntity}
                              alt=""
                              className={`${style.colorFileStyle} `}
                              onClick={() => {
                                getAddRegionsDialog(true);
                                setIsEdit(false);
                              }}
                            />
                          </div>
                          {
                            regionsData?.map((data, index) => {
                              return (
                                <>
                                  <div>
                                    <div
                                      className={
                                        index % 2 !== 0
                                          ? `${style.GeneralConfigHeaderInsideContainer} ${style.customersAdminBackground3} ${style.displayInRow}`
                                          : `${style.GeneralConfigHeaderInsideContainer} ${style.customersAdminBackground2} ${style.displayInRow}`
                                      } key={index}
                                    >
                                      <p></p>
                                      <p
                                        className={`${style.tableHeaderIndustriesFontStyle} ${style.marginLeft10}`}
                                      >
                                        {data?.region}
                                      </p>
                                      <p className={`${style.tableHeaderIndustriesFontStyle} ${style.marginLeft10}`}>{data?.mileageRate?.value}</p>
                                      <div className={style.displayInRow}>

                                        <img
                                          src={EditHcRow}
                                          alt=""
                                          className={`${style.colorFileStyle}`}
                                          onClick={() => {
                                            setIsEdit(true);
                                            getAddRegionsDialog(true);
                                            setSelectedRegion(data)
                                          }}
                                        />
                                      </div>
                                      <div className={style.displayInRow}>
                                        <img
                                          src={DeleteHcRow}
                                          alt=""
                                          className={`${style.colorFileStyle}`}
                                          onClick={() =>
                                            handleDeleteRegion(data?.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.spaceBetween}>
            <p className={style.poweredBy}>Powered by - TimeSmartAI.Inc LLP</p>
            <p className={style.poweredBy}>© TimeSmartAI.Inc</p>
          </div>
        </div>
      </div>

      {showAddRegionDialog && (
        <AddRegionsForCustomer
          getAddRegionsDialog={getAddRegionsDialog}
          isEdit={isEdit}
          getRegionsData={getRegionsList}
          regionsData={regionsData}
          selectedRegion={selectedRegion}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          getShowDeleteConfirmation={getShowDeleteConfirmation}
          getDeleteConfirmation={getDeleteConfirmation}
          confirmationText="Do you want to delete this holiday?"
        />
      )}
    </Fragment>
  );
};

export default RegionsForCustomers;
