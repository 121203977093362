import React, { useState, useEffect } from "react";
import {
  Dialog,
  Classes,
  InputGroup,
} from "@blueprintjs/core";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { POST, PUT, TenantID } from "./../dataSaver";
import style from "./index.module.scss";
import CrossPink from "./../../images/crossPink.png";

const AddRegionsForCustomer = ({
  getAddRegionsDialog,
  isEdit,
  getRegionsData,
  regionsData,
  selectedRegion
}) => {

  const [regionId, setRegionId] = useState("");
  const [regionsValue, setRegionsValue] = useState("");
  const [createdDate, setCreatedDate] = useState("");

  console.log(selectedRegion)
  useEffect(() => {
    if (isEdit) {
      setRegionId(selectedRegion?.id);
      setRegionsValue(selectedRegion?.region);
      setCreatedDate(selectedRegion?.createdDate);
    }
  }, [isEdit, selectedRegion]);

  const handleSave = async () => {

    if (regionsValue === null || regionsValue === undefined || regionsValue === "") {
      ErrorToaster('Enter Region');
      return;
    }

    if (isEdit) {
      let data = {
        "id": regionId,
        "region": regionsValue,
        "entityId": {
          "id": TenantID,
        },
        "createdDate": createdDate,
      };
      await PUT(`entity-service/region/${regionId}`, JSON.stringify(data))
        .then((response) => {
          SuccessToaster("Region Updated Successfully");
          getAddRegionsDialog(false);
          getRegionsData();
        })
        .catch((error) => {
          ErrorToaster(error);
        });
    } else {
      let data = [
        {
          "region": regionsValue,
          "entityId": {
            "id": TenantID,
          },
        },
      ];
      await POST("entity-service/region", JSON.stringify(data))
        .then((response) => {
          SuccessToaster("Region Added Successfully");
          getAddRegionsDialog(false);
          getRegionsData();
        })
        .catch((error) => {
          ErrorToaster(error);
        });
    }
  };

  return (
    <Dialog
      isOpen={getAddRegionsDialog}
      onClose={() => getAddRegionsDialog(false)}
      className={`${style.healthCareDialogStyle} ${style.dialogPaddingBottom}`}
    >
      <div
        className={`${Classes.DIALOG_BODY} ${style.extensionDialogBackground}`}
      >
        <div className={style.spaceBetween}>
          <p className={style.extensionStyle}>
            {isEdit ? "Edit" : "Add"} Regions
          </p>
          <img
            src={CrossPink}
            alt=""
            className={`${style.colorFileStyle2} ${style.marginLeft20}`}
            onClick={() => getAddRegionsDialog(false)}
          />
        </div>
        <div className={style.ReferenceListEntityBorder}></div>
        <div className={`${style.addHealthCareBoxStyle}`}>
          <div className={`${style.editHealthCareGrid2}`}>
            <div className={style.entityLableStyle}>Regions*</div>
            <div className={style.displayInRow}>
              <InputGroup
                value={regionsValue}
                onChange={(e) => setRegionsValue(e.target.value)}
                className={style.fullWidth}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={`${style.floatRight} ${style.marginTop20}`}>
            <button
              className={style.outlinedButton}
              onClick={() => getAddRegionsDialog(false)}
            >
              CANCEL
            </button>
            <button
              className={`${style.buttonStyle} ${style.marginLeft20}`}
              onClick={() => handleSave()}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddRegionsForCustomer;
