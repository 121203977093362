import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import SideBar from "./../../Components/Sidebar";
import { Checkbox, Icon, Intent } from "@blueprintjs/core";
import AddNewEntity from "./../../images/addEntity.png";
import SelectArrow from "./../../images/selectArrow.png";
import OpenFolderBlue from "./../../images/openFolderBlue.png";
import CloseFolderBlue from "./../../images/closeFolderBlue.png";
import IndustriesEntityFolder from "./../../images/industriesEntityFolder.png";
import DeleteHcRow from "./../../images/deleteHcRow.png";
import EditHcRow from "./../../images/editHcRow.png";
import CrossPink from "./../../images/crossPink.png";
import AddAdminActivity from "./addAdminActivity";
import { GET, DELETE, POST, TenantID } from "./../dataSaver";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DeleteConfirmation from "../../Components/DeleteConfirmation";
import { InputGroup } from "@blueprintjs/core";
import style from "./index.module.scss";
import LevelTwoHeader from "../../Components/LevelTwoHeader";
import CommonPurpleCheckBox from "../../Components/CommonFields/CommonPurpleCheckBox";
import {
    ADMINISTRATIVE
} from "../../Constants";

const AdminActivityForEntity = () => {
    const [showAddRegionDialog, setShowAddRegionDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedActivity, setSelectedActivity] = useState({});
    const [entityId, setEntityId] = useState("");
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");
    const [adminActivityData, setAdminActivityData] = useState([]);
    const [contractedServiceType, setContractedServiceType] = useState([]);

    const getAddAdminActivityDialog = (value) => {
        setShowAddRegionDialog(value);
        getAdminActivityList();
    };

    const getIsExpanded = (value) => {
        setIsExpanded(value);
    };

    useEffect(() => {
        getIndustryData();
        getContractedServiceType();
        getAdminActivityList();
    }, []);


    useEffect(() => {
        if (entityId !== "" && entityId !== undefined) {
            getLastModifiedDate();
        }
    }, [entityId]);

    const getIndustryData = async () => {
        const { data: entity } = await GET(`entity-service/entity/${TenantID}`);
        setEntityId(entity?.id);
    };

    const getContractedServiceType = async () => {
        const { data: contractedServiceType } = await GET(
            `entity-service/contractedServiceType`
        );
        setContractedServiceType(contractedServiceType?.filter(data => data?.serviceTypeTemplate === ADMINISTRATIVE)?.map(data => data?.paramsMetas)[0]);
    };

    console.log('setContractedServiceType', contractedServiceType)

    const getLastModifiedDate = async () => {
        const { data: lastModifiedDate } = await GET(
            `entity-service/referenceList/entity/${entityId}`
        );
        const date = new Date(lastModifiedDate.holidayList?.lastModified);
        setLastUpdatedDate(format(date, "MMM d, yyyy HH:mm"));
    };

    const getAdminActivityList = async () => {
        const { data: adminActivityList } = await GET(`contract-managment-service/contracts/adminActivity`);
        setAdminActivityData(adminActivityList)
    };


    const getShowDeleteConfirmation = (value) => {
        setShowDeleteConfirmation(value);
    };


    return (
        <Fragment>
            <div>
                <Navbar />
                <div className={style.margin20}>
                    <div
                        className={`${isExpanded ? style.bigCardGrid : style.smallCardGrid
                            }`}
                    >
                        <div>
                            <SideBar isExpanded={isExpanded} getIsExpanded={getIsExpanded}>
                                <div></div>
                            </SideBar>
                        </div>
                        <div>
                            <LevelTwoHeader
                                heading={"Admin Activity For Entity"}
                                // updatedTime={`UPDATED ON ${lastUpdatedDate} `}
                                path={"/Screens/ReferenceList/customerAdminDashboard"}
                                callingFrom={"Customer Admin"}
                                needHeader={true}
                            />

                            <div className={style.marginTop35}>
                                <div className={style.centreCardStyle}>
                                    <div className={style.margin20}>
                                        <div className={style.customersAdminColumngrid3}>
                                            <div></div>
                                            <div>
                                                <div className={style.customersAdminCardStyle3}>
                                                    <div className={`${style.AdminActivityHeaderGrid} ${style.displayInRow}`}>
                                                        <p></p>
                                                        <p className={`${style.tableHeaderIndustriesFontStyle7} `}>
                                                            Admin Activity</p>
                                                        <p className={`${style.tableHeaderIndustriesFontStyle7}`}>
                                                            Edit </p>
                                                        <p className={`${style.tableHeaderIndustriesFontStyle7} `}>
                                                            Delete </p>
                                                        <img
                                                            src={AddNewEntity}
                                                            alt=""
                                                            className={`${style.colorFileStyle} `}
                                                            onClick={() => {
                                                                getAddAdminActivityDialog(true);
                                                                setIsEdit(false);
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        adminActivityData?.map((data, index) => {
                                                            return (
                                                                <>
                                                                    <div >
                                                                        <div
                                                                            className={
                                                                                index % 2 !== 0
                                                                                    ? `${style.AdminActivityGrid} ${style.customersAdminBackground3} ${style.displayInRow}`
                                                                                    : `${style.AdminActivityGrid} ${style.customersAdminBackground2} ${style.displayInRow}`
                                                                            } key={index}
                                                                        >
                                                                            <p></p>
                                                                            <p
                                                                                className={`${style.tableHeaderIndustriesFontStyle} ${style.marginLeft10}`}
                                                                            >
                                                                                {data?.activity}
                                                                            </p>
                                                                            <p className={`${style.tableHeaderIndustriesFontStyle} ${style.marginLeft10}`}>{data?.mileageRate?.value}</p>
                                                                            <div className={style.displayInRow}>

                                                                                <img
                                                                                    src={EditHcRow}
                                                                                    alt=""
                                                                                    className={`${style.colorFileStyle}`}
                                                                                    onClick={() => {
                                                                                        setIsEdit(true);
                                                                                        getAddAdminActivityDialog(true);
                                                                                        setSelectedActivity(data)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={style.displayInRow}>
                                                                                <img
                                                                                    src={DeleteHcRow}
                                                                                    alt=""
                                                                                    className={`${style.colorFileStyle}`}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.spaceBetween}>
                        <p className={style.poweredBy}>Powered by - TimeSmartAI.Inc LLP</p>
                        <p className={style.poweredBy}>© TimeSmartAI.Inc</p>
                    </div>
                </div>
            </div>

            {showAddRegionDialog && (
                <AddAdminActivity
                    getAddAdminActivityDialog={getAddAdminActivityDialog}
                    isEdit={isEdit}
                    getAdminActivityData={getAdminActivityList}
                    adminActivityData={adminActivityData}
                    selectedActivity={selectedActivity}
                    activity={adminActivityData}
                    contractedServiceType={contractedServiceType}
                />
            )}
            {/* {showDeleteConfirmation && (
                <DeleteConfirmation
                    getShowDeleteConfirmation={getShowDeleteConfirmation}
                    getDeleteConfirmation={getDeleteConfirmation}
                    confirmationText="Do you want to delete this holiday?"
                />
            )} */}
        </Fragment>
    );
};

export default AdminActivityForEntity;
