import React, { useState, useEffect } from "react";
import {
    Dialog,
    Classes,
    InputGroup,
    TextArea,
} from "@blueprintjs/core";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { POST, PUT, TenantID, GET } from "./../dataSaver";
import style from "./index.module.scss";
import CrossPink from "./../../images/crossPink.png";
import CommonInputField from '../../Components/CommonFields/CommonInputField';
import CommonCheckBox from '../../Components/CommonFields/CommonCheckBox';
import CommonSwitch from '../../Components/CommonFields/CommonSwitch';
import CommonTextField from '../../Components/CommonFields/CommonTextField';
import CommonLabel from '../../Components/CommonFields/CommonLabel';
import CommonSelectField from '../../Components/CommonFields/CommonSelectField';

const AddAdminActivity = ({
    getAddAdminActivityDialog,
    isEdit,
    getAdminActivityData,
    regionsData,
    selectedActivity,
    activity,
    contractedServiceType
}) => {
    const [adminActivity, setAdminActivity] = useState({
        activity: '',
        podRequired: false,
        schedule: 'WEEK',
        billable: false,
        asNeeded: false,
        activityDescription: '',
    });

    console.log(adminActivity);
    useEffect(() => {
        if (isEdit) {
            setAdminActivity(selectedActivity)
        }
    }, [isEdit, selectedActivity]);

    const handleAdminActivity = (name, value) => {
        if (name === 'schedule' && value !== 'NA') {
            setAdminActivity({ ...adminActivity, [name]: value, asNeeded: false })
        } else if (name === 'schedule' && adminActivity?.asNeeded) {
            setAdminActivity({ ...adminActivity, [name]: "NA" });
        } else {
            setAdminActivity({ ...adminActivity, [name]: value });
        }
    }

    const activityToAdd = async () => {
        // setIsLoading(true);
        if (adminActivity?.activity === '') {
            ErrorToaster('Administrative Service Name is Mandatory');
            return;
        }
        if (contractedServiceType?.[1]?.defaultValue === "true" && adminActivity?.activityDescription === '') {
            ErrorToaster('Administrative Service Description is Mandatory');
            return;
        }
        if (!isEdit && activity?.map(data => data?.activity).includes(adminActivity?.activity)) {
            ErrorToaster('Administrative Service Name Already Exists');
            return;
        }
        let data = {
            ...(isEdit && { "id": adminActivity?.id }),
            "activity": adminActivity?.activity,
            "tenant": {
                "id": TenantID
            },
            "podRequired": adminActivity?.podRequired,
            "schedule": adminActivity?.schedule,
            "billable": adminActivity?.billable,
            "asNeeded": adminActivity?.asNeeded,
            "activityDescription": adminActivity?.activityDescription,
        }
        if (!isEdit) {
            await POST(`contract-managment-service/contracts/adminActivity`, data)
                .then(response => {
                    SuccessToaster('Activity Added to List');
                })
                .catch(error => {
                    ErrorToaster('Adding Activity To List Failed');
                })
        } else {
            await PUT(`contract-managment-service/contracts/adminActivity/${adminActivity?.id}`, data)
                .then(response => {
                    SuccessToaster('Activity Updated');
                })
                .catch(error => {
                    ErrorToaster('Updating Activity Failed');
                })
        }

        getAddAdminActivityDialog(false)
    }

    return (
        <Dialog
            isOpen={getAddAdminActivityDialog}
            onClose={() => getAddAdminActivityDialog(false)}
            className={`${style.healthCareDialogStyle} ${style.dialogPaddingBottom}`}
        >
            <div
                className={`${Classes.DIALOG_BODY} ${style.extensionDialogBackground}`}
            >
                <div className={style.spaceBetween}>
                    <p className={style.extensionStyle}>
                        {isEdit ? "Edit" : "Add"} Admin Activity
                    </p>
                    <img
                        src={CrossPink}
                        alt=""
                        className={`${style.colorFileStyle2} ${style.marginLeft20}`}
                        onClick={() => getAddAdminActivityDialog(false)}
                    />
                </div>
                <div className={`${style.addonAddBox} ${style.marginTop20}`}>
                    <div className={`${style.addManagerGrid}`}>
                        <CommonLabel value='Additional Administrative Services Name' />
                        <CommonInputField placeholder='Administrative Service Name' className={style.fullWidth} value={adminActivity?.activity} onChange={(e) => handleAdminActivity('activity', e.target.value)} />
                    </div>
                    {
                        contractedServiceType?.[1]?.defaultValue === "true" &&
                        <div className={`${style.addManagerGrid}`}>
                            <CommonLabel value='Admin Service Description' />
                            <TextArea rows={3} placeholder="Enter Description" value={adminActivity?.activityDescription} onChange={(e) => handleAdminActivity('activityDescription', e.target.value)}
                            />
                        </div>
                    }


                    <div className={`${style.addManagerGrid} ${style.marginTop20}`}>
                        <CommonLabel value='Classify As Billable Activity' />
                        <div className={`${style.threeFieldWidth} `}>
                            <CommonSwitch label={adminActivity?.billable ? 'YES' : 'NO'} className={`${style.switchFontStyle} ${style.flexLeft} ${style.textAlignLeft}`} checked={adminActivity?.billable} onChange={(e) => handleAdminActivity('billable', !adminActivity?.billable)} />
                        </div>
                    </div>

                    <div className={`${style.addManagerGrid} ${style.marginTop20}`}>
                        <CommonLabel value='Proof Of Completion / Documentation Required' />
                        <div className={style.displayInRow}>
                            <div className={`${style.threeFieldWidth} `}>
                                <CommonSwitch label={adminActivity?.podRequired ? 'YES' : 'NO'} className={`${style.switchFontStyle} ${style.flexLeft} ${style.textAlignLeft}`} checked={adminActivity?.podRequired} onChange={(e) => handleAdminActivity('podRequired', !adminActivity?.podRequired)} />
                            </div>
                            <div className={style.threeFieldWidth}>
                                <CommonLabel value='Frequency*' />
                                <CommonSelectField className={`${style.fullWidth}`}
                                    value={adminActivity?.schedule || ''}
                                    onChange={(e) => handleAdminActivity('schedule', e.target.value)}
                                    firstOptionLabel={'Select Frequency'} firstOptionValue={''}
                                    valueList={['NA', 'WEEK', 'MONTH', 'YEAR']}
                                    labelList={['NA', 'Per Week', 'Per Month', 'Per Year']}
                                    disabledList={[false, false, false, false, false]} />
                            </div>
                            <div className={`${style.marginTop20} ${style.marginLeft20}`}>
                                <CommonCheckBox checked={adminActivity?.asNeeded} label='As Needed' onChange={(e) => setAdminActivity({ ...adminActivity, asNeeded: e.target.checked, schedule: 'NA' })} />
                            </div>

                        </div>
                    </div>

                    <div>
                        <div className={`${style.twoCol} ${style.marginTop20}`}>
                            <button className={`${style.outlinedButton} ${style.fullWidth}`} onClick={(e) => { getAddAdminActivityDialog(false); }}>CANCEL</button>
                            <button className={`${style.buttonStyle} ${style.fullWidth}`} onClick={(e) => { activityToAdd() }}>SAVE</button>
                        </div>
                        <br />
                    </div>
                </div>

            </div>
        </Dialog>
    );
};

export default AddAdminActivity;


{/* <div className={`${style.addonAddBox} ${style.marginTop20}`}>
                    <div className={`${style.addManagerGrid}`}>
                        <CommonLabel value='Additional Administrative Services Name' />
                        <CommonInputField placeholder='Administrative Service Name' className={style.fullWidth} value={adminActivity?.activity} onChange={(e) => handleAdminActivity('activity', e.target.value)} />
                    </div>

                    <div className={`${style.addManagerGrid} ${style.marginTop20}`}>
                        <CommonLabel value='Classify As Billable Activity' />
                        <div className={`${style.threeFieldWidth} `}>
                            <CommonSwitch label={adminActivity?.billable ? 'YES' : 'NO'} className={`${style.switchFontStyle} ${style.flexLeft} ${style.textAlignLeft}`} checked={adminActivity?.billable} onChange={(e) => handleAdminActivity('billable', !adminActivity?.billable)} />
                        </div>
                    </div>

                    <div className={`${style.addManagerGrid} ${style.marginTop20}`}>
                        <CommonLabel value='Proof Of Completion / Documentation Required' />
                        <div className={style.displayInRow}>
                            <div className={`${style.threeFieldWidth} `}>
                                <CommonSwitch label={adminActivity?.podRequired ? 'YES' : 'NO'} className={`${style.switchFontStyle} ${style.flexLeft} ${style.textAlignLeft}`} checked={adminActivity?.podRequired} onChange={(e) => handleAdminActivity('podRequired', !adminActivity?.podRequired)} />
                            </div>
                            <div className={style.threeFieldWidth}>
                                <CommonLabel value='Frequency*' />
                                <CommonSelectField className={`${style.fullWidth}`}
                                    value={adminActivity?.schedule || ''}
                                    onChange={(e) => handleAdminActivity('schedule', e.target.value)}
                                    firstOptionLabel={'Select Frequency'} firstOptionValue={''}
                                    valueList={['NA', 'WEEK', 'MONTH', 'YEAR']}
                                    labelList={['NA', 'Per Week', 'Per Month', 'Per Year']}
                                    disabledList={[false, false, false, false, false]} />
                            </div>
                            <div className={`${style.marginTop20} ${style.marginLeft20}`}>
                                <CommonCheckBox checked={adminActivity?.asNeeded} label='As Needed' onChange={(e) => setAdminActivity({ ...adminActivity, asNeeded: e.target.checked, schedule: 'NA' })} />
                            </div>

                        </div>
                    </div>

                    <div>
                        <div className={`${style.twoCol} ${style.marginTop20}`}>
                            <button className={`${style.outlinedButton} ${style.fullWidth}`} onClick={(e) => { setShowAdminActivity(false); setEditAdminActivitySelected(false); }}>CANCEL</button>
                            <button className={`${style.buttonStyle} ${style.fullWidth} ${isLoading ? style.disabled : ''}`} onClick={(e) => { submit() }}>SAVE</button>
                        </div>
                        <br />
                    </div>
                </div> */}



// const activityToAdd = async () => {
//     setIsLoading(true);
//     if (adminActivity?.activity === '') {
//         ErrorToaster('Administrative Service Name is Mandatory');
//         return;
//     }
//     if (activity?.map(data => data?.activity).includes(adminActivity?.activity)) {
//         ErrorToaster('Administrative Service Name Already Exists');
//         return;
//     }
//     let data = {
//         "activity": adminActivity?.activity,
//         "tenant": {
//             "id": TenantID
//         },
//         "podRequired": adminActivity?.podRequired,
//         "schedule": adminActivity?.schedule,
//         "billable": adminActivity?.billable,
//         "asNeeded": adminActivity?.asNeeded,
//     }
//     await POST(`contract-managment-service/contracts/adminActivity`, data)
//         .then(response => {
//             SuccessToaster('Activity Added to List');
//             getAdminActivityList();
//         })
//         .catch(error => {
//             ErrorToaster('Adding Activity To List Failed');
//         })
//     setAdminActivity({ ...adminActivity, activity: '' })
//     setShowAdminActivity(false);
//     setEditAdminActivitySelected(false);
//     setIsLoading(false);
// }