import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import SideBar from "./../../Components/Sidebar";
import { Checkbox, Icon, Intent, Divider, Tag } from "@blueprintjs/core";
import AddNewEntity from "./../../images/addEntity.png";
import SelectArrow from "./../../images/selectArrow.png";
import OpenFolderBlue from "./../../images/openFolderBlue.png";
import CloseFolderBlue from "./../../images/closeFolderBlue.png";
import IndustriesEntityFolder from "./../../images/industriesEntityFolder.png";
import DeleteHcRow from "./../../images/deleteHcRow.png";
import EditHcRow from "./../../images/editHcRow.png";
import CrossPink from "./../../images/crossPink.png";
import AddMileageRateForCustomer from "./addMileageRateForCustomer";
import { GET, DELETE, POST, TenantID, PUT } from "./../dataSaver";
import { ErrorToaster, SuccessToaster } from "./../../utils/toaster";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DeleteConfirmation from "../../Components/DeleteConfirmation";
import { InputGroup } from "@blueprintjs/core";
import style from "./index.module.scss";
import LevelTwoHeader from "../../Components/LevelTwoHeader";
import CommonSwitch from '../../Components/CommonFields/CommonSwitch';
import CommonPurpleCheckBox from "../../Components/CommonFields/CommonPurpleCheckBox";
import CommonLabel from "../../Components/CommonFields/CommonLabel";
import CommonTextField from "../../Components/CommonFields/CommonTextField";
import CommonSelectField from "../../Components/CommonFields/CommonSelectField";
import CommonRadio from "../../Components/CommonFields/CommonRadio";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AddIcon from "@mui/icons-material/Add";
import { map } from "d3";

const InvoiceWorkflow = () => {
    const [showAddCompanyDialog, setShowAddCompanyDialog] = useState(false);
    const [title, setTitle] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMileageRate, setSelectedMileageRate] = useState({});
    const [entityId, setEntityId] = useState("");
    const [lastUpdatedDate, setLastUpdatedDate] = useState("");
    const [mileageRateData, setMileageRateData] = useState([]);
    const getAddMileageRateDialog = (value) => {
        setShowAddCompanyDialog(value);
    };
    const [workflowConfig, setWorkflowConfig] = useState([]);
    const [controlLevels, setControlLevels] = useState([{
        '1': {
            "hasSpecificUserTitles": false,
            "userTitles": [],
            "reviewApproveRequirement": "",
            "hasTriggerCondition": false,
            "conditions": []
        }
    }]);
    const [requiredDocumentation, setRequiredDocumentation] = useState(false);
    const [paymentConfirmationMethod, setPaymentConfirmationMethod] = useState("");
    const [certificationText, setCertificationText] = useState("");
    const [controlLevelFields, setControlLevelFields] = useState();
    const [approvedInvoicePaymentProcess, setApprovedInvoicePaymentProcess] = useState("");

    const getIsExpanded = (value) => {
        setIsExpanded(value);
    };

    useEffect(() => {
        getIndustryData();
        getInvoiceWorkflow();
        getTitle();
    }, []);

    useEffect(() => {
        getMileageRateList();
    }, []);

    useEffect(() => {
        handleControlLevelFields();
    }, [controlLevels])


    useEffect(() => {
        if (entityId !== "" && entityId !== undefined) {
            getLastModifiedDate();
        }
    }, [entityId]);

    const getIndustryData = async () => {
        const { data: entity } = await GET(`entity-service/entity/${TenantID}`);
        setEntityId(entity?.id);
    };

    const getLastModifiedDate = async () => {
        const { data: lastModifiedDate } = await GET(
            `entity-service/referenceList/entity/${entityId}`
        );
        const date = new Date(lastModifiedDate.holidayList?.lastModified);
        setLastUpdatedDate(format(date, "MMM d, yyyy HH:mm"));
    };

    const getMileageRateList = async () => {
        const { data: mileageRateData } = await GET(
            `entity-service/mileageRate?X-tenantID=${TenantID}`
        );
        setMileageRateData(mileageRateData)
    };

    const getTitle = async () => {
        const { data: title } = await GET(`entity-service/functionalTitlesForCSPType`);
        setTitle(title);
    }

    const handleControlLevel = (controlLevel) => {
        let temp = [];
        for (let i = 0; i < controlLevel; i++) {
            temp.push({
                [i + 1]: {
                    "hasSpecificUserTitles": false,
                    "userTitles": [],
                    "reviewApproveRequirement": "",
                    "hasTriggerCondition": false,
                    "conditions": []
                }
            })
        }
        setControlLevels(temp);
        handleControlLevelFields();
    }

    const handleDeleteMileageRate = async (id) => {
        await DELETE(`entity-service/mileageRate/${id}`)
            .then((response) => {
                SuccessToaster("Mileage Rate Deleted Successfully");
                getMileageRateList();
                // getLastModifiedDate();
            })
            .catch((error) => {
                ErrorToaster(error);
            });
    };


    const getShowDeleteConfirmation = (value) => {
        setShowDeleteConfirmation(value);
    };

    const getDeleteConfirmation = (value) => {
        if (value) {
            // handleDeleteMileageRate(mileageId);
        }
    };

    const getInvoiceWorkflow = async () => {
        const { data: data } = await GET(
            `timesheet-management-service/invoice/workflowConfig`
        );
        if (data?.length !== 0) {
            setWorkflowConfig(data);
            let controlTemp = [];
            Object.keys(data?.[0]?.controlLevels)?.map((condition, index) => {
                console.log('data', condition, 'index', index);
                controlTemp.push({ [condition]: data?.[0]?.controlLevels?.[condition] })
            })
            console.log('controlTemp', controlTemp);
            setControlLevels(controlTemp);
            setCertificationText(data?.[0]?.certificationText);
            setApprovedInvoicePaymentProcess(data?.[0]?.approvedInvoicePaymentProcess);
            setPaymentConfirmationMethod(data?.[0]?.paymentConfirmationMethod);
            setRequiredDocumentation(data?.[0]?.requireDocumentationOfInvoicePayment);
            setIsEdit(true);
        }
    }



    const handleSave = async () => {
        let controlLevelData = {};
        controlLevels?.map((data, index) => {
            console.log('control level data', data[`${index + 1}`])
            controlLevelData[`${index + 1}`] = data[`${index + 1}`]
        })
        let data = {
            ...(isEdit && {
                "id": workflowConfig?.[0]?.id,
            }),
            "site": {
                "id": "642471431b70b07241d37aa2",
                "name": "San Mateo Medical Center"
            },
            "noOfControlLevel": controlLevels?.length,
            "controlLevels": controlLevelData,
            "approvedInvoicePaymentProcess": approvedInvoicePaymentProcess,
            "requireDocumentationOfInvoicePayment": requiredDocumentation,
            "paymentConfirmationMethod": paymentConfirmationMethod,
            "certificationText": certificationText,
        }

        if (isEdit) {
            await PUT(`timesheet-management-service/invoice/workflowConfig/${workflowConfig?.[0]?.id}`, JSON.stringify(data))
                .then((response) => {
                    SuccessToaster("Workflow Config Added Successfully");
                })
                .catch((error) => {
                    ErrorToaster('Updating Workflow Config Failed');
                });
        } else {
            await POST('timesheet-management-service/invoice/workflowConfig', JSON.stringify(data))
                .then((response) => {
                    SuccessToaster("Workflow Config Added Successfully");
                })
                .catch((error) => {
                    ErrorToaster('Updating Workflow Config Failed');
                });
        }



    }

    const titleTags = (userTitle) => {
        userTitle
            ?.filter((data) => userTitle.map((userTitle) => userTitle?.id === userTitle?.id))
            ?.map((tag, index) => {
                // const onRemove = () => {
                //     setSelectedRoles(
                //         selectedRoles
                //             .filter((t) => t?.roleName !== tag?.roleName)
                //             ?.map((data) => data)
                //     );
                // };
                return (
                    <Tag
                        // key={index}
                        // onRemove={contractStatus === "ACTIVE" ? () => { } : onRemove}
                        large={true}
                        className={style.tagStyle}
                    >
                        {tag?.title}
                    </Tag>
                );
            });
    }


    // const titleTags = (<Tag
    //     // key={index}
    //     // onRemove={contractStatus === "ACTIVE" ? () => { } : onRemove}
    //     large={true}
    //     className={style.tagStyle}
    // >
    //     test
    // </Tag>)

    const removeAndCondition = (controlLevel, conditionLevel, index) => {
        let controlTemp = controlLevels;
        controlTemp?.filter((control, index) => index === controlLevel)?.map((control => control?.[`${controlLevel + 1}`]['conditions']?.filter((control, indexValue) => indexValue === conditionLevel)?.map(condition => {
            let temp = condition?.['triggerCondition'];
            let filteredTemp = temp.filter((triggerCondition, triggerIndex) => triggerIndex !== index)?.map(data => data);
            console.log('filtered Temp', filteredTemp);
            condition['triggerCondition'] = filteredTemp;
        })));

        setControlLevels(controlTemp);
        handleControlLevelFields();
    }


    const handleAndConditionFields = (controlLevel, conditionLevel) => {
        let temp = [];
        let andCondition = controlLevels?.filter((data, index) => index === controlLevel)?.map(data => data[`${controlLevel + 1}`]['conditions']?.filter((condition, indexValue) => conditionLevel === indexValue)?.map(condition => condition)[0])[0]?.['triggerCondition'];
        console.log('and consition', andCondition);
        andCondition?.filter((data, index) => index !== 0)?.map((data, index) => {
            temp.push(<div>
                <CommonLabel value="And" className={style.purpleFont}
                />
                <div className={style.conditionGrid}>
                    <CommonSelectField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'field', index + 1)}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={"Select Trigger Condition"}
                        firstOptionValue={"0"}
                        valueList={['TOTAL_INVOICE_AMOUNT']}
                        labelList={['Total Invoice Amount']}
                        disabledList={[false]}
                        value={data?.field}
                    />
                    <CommonSelectField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'comparisonOperator', index + 1)}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={"Select Comparison Operator"}
                        firstOptionValue={"0"}
                        valueList={['EQUAL', 'GREATER_THAN', 'LESS_THAN', 'GREATER_THAN_OR_EQUAL', 'LESS_THAN_OR_EQUAL']}
                        labelList={['Equal', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal']}
                        disabledList={[false, false, false, false, false]}
                        value={data?.comparisonOperator}

                    />
                    <CommonTextField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'value', index + 1)}
                        className={`${style.fullWidth}`}
                        value={data?.value}

                    />
                    <CommonSelectField
                        onChange={(e) => handleValueCondition(controlLevel, conditionLevel, e.target.value, 'unit', index + 1)}
                        className={`${style.fullWidth}`}
                        firstOptionLabel={"Select Unit"}
                        firstOptionValue={"0"}
                        valueList={['PER_INVOICE', 'CONTRACT_YEAR', 'CONTRACT_PERIOD']}
                        labelList={['Per Invoice', 'Contract Year', 'Contract Period']}
                        disabledList={[false, false, false]}
                        value={data?.unit}

                    />
                    <Icon icon="trash" color="#52575D" className={style.marginTop10} onClick={() => removeAndCondition(controlLevel, conditionLevel, index + 1)} />

                </div>
            </div>)
        })

        console.log('and condition temp value log', temp)


        return temp;

    }

    console.log('Control level', controlLevels, 'certification text', certificationText);

    const handleAddConditionFields = (index) => {
        let temp = []
        let conditions = controlLevels?.filter((data, indexValue) => indexValue === index)?.map(data => data?.[`${index + 1}`]?.conditions)[0];
        console.log('conditions', conditions);
        conditions?.map((data, indexValue) => {

            temp.push(<div className={`${style.conditionCardStyle} ${style.marginTop20}`}>
                <CommonTextField
                    type="text"
                    className={style.fullWidth}
                    onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'conditionLabel', 0)}
                    defaultValue={data?.conditionLabel}
                />
                <div className={style.marginTop20}>
                    <CommonLabel value="when:"
                    // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                    />
                    <div className={style.conditionGrid}>
                        <CommonSelectField
                            onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'field', 0)}
                            className={`${style.fullWidth}`}
                            firstOptionLabel={"Select Trigger Condition"}
                            firstOptionValue={"0"}
                            valueList={['TOTAL_INVOICE_AMOUNT']}
                            labelList={['Total Invoice Amount']}
                            disabledList={[false]}
                            value={data?.triggerCondition?.[0]?.field}
                        />
                        <CommonSelectField
                            onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'comparisonOperator', 0)}
                            className={`${style.fullWidth}`}
                            firstOptionLabel={"Select Comparison Operator"}
                            firstOptionValue={"0"}
                            valueList={['EQUAL', 'GREATER_THAN', 'LESS_THAN', 'GREATER_THAN_OR_EQUAL', 'LESS_THAN_OR_EQUAL']}
                            labelList={['Equal', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal']}
                            disabledList={[false, false, false, false, false]}
                            value={data?.triggerCondition?.[0]?.comparisonOperator}
                        />
                        <CommonTextField
                            onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'value', 0)}
                            className={`${style.fullWidth}`}
                            value={data?.triggerCondition?.[0]?.value}
                        />
                        <CommonSelectField
                            onChange={(e) => handleValueCondition(index, indexValue, e.target.value, 'unit', 0)}
                            className={`${style.fullWidth}`}
                            firstOptionLabel={"Select Unit"}
                            firstOptionValue={"0"}
                            valueList={['PER_INVOICE', 'CONTRACT_YEAR', 'CONTRACT_PERIOD']}
                            labelList={['Per Invoice', 'Contract Year', 'Contract Period']}
                            disabledList={[false, false, false]}
                            value={data?.triggerCondition?.[0]?.unit}
                        />
                        {
                            data['triggerCondition']?.length > 1 && <Icon icon="trash" color="#52575D" className={style.marginTop10} onClick={() => removeAndCondition(index, indexValue, 0)} />
                        }

                    </div>

                    {handleAndConditionFields(index, indexValue)}

                    <div className={`${style.addIcon} ${style.marginTop20}`}>
                        <AddIcon
                            sx={{ fontSize: 25, }} className={style.addIconButton}
                            onClick={() => {
                                handleAndCondition(index, indexValue);
                            }}
                        />
                    </div>
                </div>


            </div>)

        }
        )

        console.log('temp vslue ', temp)
        // setConditionFields(temp);
        return temp;

    }

    const handleValues = (label, value, index) => {
        let temp = controlLevels;
        console.log('controlLevels', controlLevels);
        temp?.filter((data, indexValue) => indexValue === index)?.map(data => {
            console.log('data test', data);
            if (label !== 'userTitles') {
                data[`${index + 1}`][label] = value;
            }
            if (label === 'hasTriggerCondition' && value === true) {
                data[`${index + 1}`]['conditions'] = [
                    {
                        "conditionLabel": "",
                        "triggerCondition": [
                            {
                                "field": "",
                                "comparisonOperator": "",
                                "value": "",
                                "unit": "",
                                "criteriaOperator": "AND"
                            }
                        ]
                    }]
            }
            else if (label === 'userTitles') {
                let selectedTitle = title?.filter(data => data?.id === value)?.map(data => data)?.[0];
                data[`${index + 1}`][label].push({ title: selectedTitle?.title, id: selectedTitle?.id })
            }
            else if (label === 'hasTriggerCondition' && value === false && data[`${index + 1}`]['reviewApproveRequirement'] === 'CONDITIONAL_INVOICE') {
                data[`${index + 1}`][label] = true;
            }
            else if (label === 'hasTriggerCondition' && value === false) {
                data[`${index + 1}`]['conditions'] = [];
            }
            else if (label === 'reviewApproveRequirement' && value === 'CONDITIONAL_INVOICE') {
                data[`${index + 1}`]['hasTriggerCondition'] = true;
                data[`${index + 1}`]['conditions'] = [
                    {
                        "conditionLabel": "",
                        "triggerCondition": [
                            {
                                "field": "",
                                "comparisonOperator": "",
                                "value": "",
                                "unit": "",
                                "criteriaOperator": "AND"
                            }
                        ]
                    }]
            } else {

            }
        })
        setControlLevels(temp);
        console.log('temp values ', temp);
        handleControlLevelFields();
    }

    const handleAddCondition = (index) => {
        let temp = controlLevels;
        temp?.filter((data, indexValue) => indexValue === index)?.map(data => {
            data[`${index + 1}`]['conditions'].push({
                "conditionLabel": "",
                "triggerCondition": [
                    {
                        "field": "",
                        "comparisonOperator": "",
                        "value": "",
                        "unit": "",
                        "criteriaOperator": "AND"
                    }
                ]
            })
        })
        setControlLevels(controlLevels);
        handleControlLevelFields();
    }

    const handleAndCondition = (controlLevel, conditionLevel) => {
        let temp = controlLevels;
        temp?.filter((data, indexValue) => indexValue === controlLevel)?.map(data => {
            data[`${controlLevel + 1}`]['conditions']?.filter((condition, index) => index === conditionLevel)?.map(condition => {
                condition?.['triggerCondition']?.push({
                    "field": "",
                    "comparisonOperator": "",
                    "value": "",
                    "unit": "",
                    "criteriaOperator": "AND"
                })
            })
        });
        console.log('And Condition Function', temp);
        setControlLevels(temp);
        handleControlLevelFields();
    }

    console.log('title', title);

    const handleControlLevelFields = () => {
        let field = [];

        controlLevels?.map((data, index) => {
            console.log('value testing', data[`${index + 1}`]['hasSpecificUserTitles'])
            field.push(<div >
                <div className={style.marginTop20}>
                    <CommonLabel
                        value={`Control Level ${index + 1} Setup Reviewer / Approver`}
                        className={style.boldText}
                    />
                </div>
                <div className={style.padding20}>
                    <div
                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                    >
                        <CommonLabel value="By Specific User Titles"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <div>
                            <div className={`${style.displayInRow} `}>
                                <div>
                                    <CommonSwitch
                                        checked={data[`${index + 1}`]['hasSpecificUserTitles']}
                                        // disabled={
                                        //     selectContractInfo === "INDIVIDUAL" && true
                                        // }
                                        key={`user${index}`}
                                        className={`${style.switchFontStyle} ${style.textAlignLeft} ${style.flexLeft}`}
                                        onChange={(e) => handleValues('hasSpecificUserTitles', e.target.checked, index)}
                                        label={
                                            data[`${index + 1}`]['hasSpecificUserTitles'] ? "YES" : "NO"
                                        }
                                    />
                                </div>
                                {
                                    // data[`${index + 1}`]['hasSpecificUserTitles'] &&
                                    //     <div>
                                    //         <div>
                                    //             <CommonSelectField
                                    //                 onChange={(e) => handleValues('userTitles', e.target.value, index)}
                                    //                 className={`${style.fullWidth}`}
                                    //                 firstOptionLabel={"Select User Title"}
                                    //                 firstOptionValue={"0"}
                                    //                 valueList={title?.map(data => data?.id)}
                                    //                 labelList={title?.map(data => data?.title)}
                                    //                 disabledList={title?.map(data => false)}
                                    //             />

                                    //         </div>
                                    //         <div className={`${style.marginTop20}`}>{titleTags(data[`${index + 1}`]['userTitles'])}</div>
                                    //     </div>
                                }


                            </div>
                        </div>
                    </div>
                    <div
                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                    >
                        <CommonLabel value="Review & Approval Requirement"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <CommonRadio
                            className={`${style.leftAlign}`}
                            value={data[`${index + 1}`]['reviewApproveRequirement']}
                            onChange={(e) => handleValues('reviewApproveRequirement', e.target.value, index)}
                            radioValue={['ALL_INVOICE', 'CONDITIONAL_INVOICE']}
                            label={['Required For All Invoices', 'Only Applies to Invoices meeting Focused Review Conditions']}
                        />
                    </div>
                    <div
                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                    >
                        <CommonLabel value="Setup Trigger Conditions for Invoices requiring focused Review"
                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                        />
                        <div className={`${style.spaceBetween}`}>
                            <div >
                                <CommonSwitch
                                    checked={data[`${index + 1}`]['hasTriggerCondition']}
                                    className={`${style.switchFontStyle} ${style.textAlignLeft} ${style.flexLeft}`}
                                    onChange={(e) => handleValues('hasTriggerCondition', e.target.checked, index)}
                                    label={
                                        data[`${index + 1}`]['hasTriggerCondition'] ? "YES" : "NO"
                                    }
                                />
                            </div>
                            <div>
                                <button
                                    className={`${style.newContractOutlinedButton} ${style.marginTop10}  ${style.cursorPointer
                                        }`}
                                    onClick={() => { handleAddCondition(index); }}
                                >
                                    ADD CONDITION
                                </button>
                            </div>

                        </div>

                    </div>
                    {handleAddConditionFields(index)}
                </div>
                <Divider className={style.marginTop20} />
            </div>)

        })

        setControlLevelFields(field)
    }

    console.log('Add condition count', controlLevelFields)

    const handleValueCondition = (controlLevel, conditionLevel, value, label, triggerConditionIndex) => {
        let temp = controlLevels;
        temp?.filter((data, indexValue) => indexValue === controlLevel)?.map((data, index) => {
            console.log('data test in line 522', data)
            data?.[`${controlLevel + 1}`]?.['conditions']?.filter((condition, index) => index === conditionLevel)?.map(condition => {
                console.log('inside handlevalue condition', condition);
                if (label === "conditionLabel") {
                    condition[label] = value;
                } else {
                    condition['triggerCondition'][triggerConditionIndex][label] = value;
                }
            })
        })
        console.log('value change temp', temp);
        setControlLevels(temp);
        handleControlLevelFields();

    }

    return (
        <Fragment>
            <div>
                <Navbar />
                <div className={style.margin20}>
                    <div
                        className={`${isExpanded ? style.bigCardGrid : style.smallCardGrid
                            }`}
                    >
                        <div>
                            <SideBar isExpanded={isExpanded} getIsExpanded={getIsExpanded}>
                                <div></div>
                            </SideBar>
                        </div>
                        <div>
                            <LevelTwoHeader
                                heading={"INVOICE REVIEW / APPROVAL WORKFLOW SETUP"}
                                // updatedTime={`UPDATED ON ${lastUpdatedDate} `}
                                path={"/Screens/ReferenceList/customerAdminDashboard"}
                                callingFrom={"Customer Admin"}
                                needHeader={true}
                            />

                            <div >
                                <div className={`${style.centreCardStyle} ${style.padding20}`}>
                                    <div className={style.twoCol}>
                                        <div className={`${style.twoCol} ${style.marginTop20} `}>
                                            <CommonLabel
                                                value="Control Levels to Setup for Invoice Approval"
                                                className={style.boldText}
                                            />
                                            <div>
                                                <CommonSelectField
                                                    value={controlLevels?.length}
                                                    onChange={(e) => {
                                                        handleControlLevel(parseInt(e.target.value));
                                                    }}
                                                    className={`${style.fullWidth} `}
                                                    firstOptionLabel={"Select Control Level"}
                                                    firstOptionValue={""}
                                                    valueList={[1, 2, 3, 4, 5]}
                                                    labelList={[1, 2, 3, 4, 5]}
                                                    disabledList={[false, false, false, false, false]}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <br />
                                    <div className={style.marginTop20}>
                                        {controlLevelFields}
                                    </div>
                                    <div
                                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                                    >
                                        <CommonLabel value="Approved Invoice Payment Processing"
                                        />
                                        <CommonRadio
                                            className={`${style.leftAlign}`}
                                            value={approvedInvoicePaymentProcess}
                                            onChange={(e) => setApprovedInvoicePaymentProcess(e.target.value)}
                                            radioValue={['FINANCE_USER', 'EXTERNAL_SYSTEM']}
                                            label={['By Finance User (PDF Printout)', 'Through External System Interface']}
                                        />
                                    </div>
                                    <Divider className={style.marginTop20} />
                                    <div
                                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                                    >
                                        <CommonLabel value="Require Documentation of Invoice Payment"
                                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                                        />
                                        <div>
                                            <div className={`${style.displayInRow} `}>
                                                <CommonSwitch
                                                    checked={requiredDocumentation}
                                                    className={`${style.switchFontStyle} ${style.textAlignLeft} ${style.flexLeft}`}
                                                    onChange={() => setRequiredDocumentation(!requiredDocumentation)}
                                                    label={
                                                        requiredDocumentation ? "YES" : "NO"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`${style.addManagerGrid} ${style.marginTop20} ${style.marginLeft20}`}
                                    >
                                        <CommonLabel value="Payment Confirmation Method"
                                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                                        />
                                        <CommonRadio
                                            className={`${style.leftAlign}`}
                                            value={paymentConfirmationMethod}
                                            onChange={(e) => setPaymentConfirmationMethod(e.target.value)}
                                            radioValue={['MANUAL', 'EXTERNAL_SYSTEM']}
                                            label={['Manual Data Entry (Paid Invoice Data)', 'Through External System Interface']}
                                        />
                                    </div>
                                    <Divider className={style.marginTop20} />
                                    <div
                                        className={`${style.addManagerGrid} ${style.marginTop20} `}
                                    >
                                        <CommonLabel value="Reviewer & Approver Certification text for signing off on Approved Invoices"
                                        // className={editService && isDesignatedSpecificContractor && (!usersTags || usersTags?.length === 0) ? style.redLable : ""}
                                        />
                                        <div>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={
                                                    certificationText
                                                }
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setCertificationText(data);
                                                }}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "height",
                                                            "150px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                config={{
                                                    placeholder: 'Type your content here...',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${style.floatRight} ${style.marginTop10}`}>
                                        <button
                                            className={`${style.buttonStyle} ${style.marginLeft20}`}
                                            onClick={() => handleSave()}
                                        >
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.spaceBetween}>
                        <p className={style.poweredBy}>Powered by - TimeSmartAI.Inc LLP</p>
                        <p className={style.poweredBy}>© TimeSmartAI.Inc</p>
                    </div>
                </div>
            </div>

            {
                showAddCompanyDialog && (
                    <AddMileageRateForCustomer
                        getAddMileageRateDialog={getAddMileageRateDialog}
                        isEdit={isEdit}
                        getMileageRateData={getMileageRateList}
                        mileageRateData={mileageRateData}
                        selectedYear={selectedYear}
                        selectedMileageRate={selectedMileageRate}
                    />
                )
            }
            {
                showDeleteConfirmation && (
                    <DeleteConfirmation
                        getShowDeleteConfirmation={getShowDeleteConfirmation}
                        getDeleteConfirmation={getDeleteConfirmation}
                        confirmationText="Do you want to delete this holiday?"
                    />
                )
            }
        </Fragment >
    );
};

export default InvoiceWorkflow;
